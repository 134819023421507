<template>
  <div class="f-container">
    <div
      class="page-outter"
      style="width: 60%"
    >
      <div class="ff-outter">
        <div class="d1">
<!--          <img-->
<!--            src="./images/jyw.png"-->
<!--            style="width: 194px"-->
<!--          />-->
<!--          <span>九为（浙江）网络科技有限公司</span> -->
          <div style="display: flex;align-items: flex-end;">
            <img style="width: 46px;height: 31px" src="@/assets/images/1-10.png">
            <span style="margin-left: 10px;font-size: 24px">海南九为医学科技有限公司</span>
          </div>
<!--          <img-->
<!--            src="./images/emmgz.png"-->
<!--            style="margin-top: 20px;width: 100px;"-->
<!--          />-->
        </div>
        <div class="d2">
          <span class="s1">联系我们</span>
          <span class="s2">400-860-1033</span>
          <span class="s3">海南省澄迈县老城镇高新技术产业示范区海南生态软件园孵化楼四楼5001</span>
<!--          <span class="s3">jiuyy@91medicine.cn</span>-->
<!--          <span-->
<!--            class="s4"-->
<!--            @click="goSettle"-->
<!--          >客户入驻审核规范</span>-->
        </div>
      </div>
      <div class="t-s-footer">
<!--        <div><span @click="showImg('zzdxywjy')">增值电信业务经营许可证：浙B2-20210189 ｜ </span><span @click="showImg('wljyxkz')">网络文化经营许可证：浙网文(2020)5940-260号 ｜ </span><span @click="showImg('hlwzgzs')">互联网药品信息服务资格证：(浙)-经营性-2020-0061</span></div>-->
        <div style="margin-top: 10px"><span>营业执照：91460000MA5TYLJJ11 | 琼ICP备2021009294号-1 | <router-link target="_blank" :to="{path:'/zizhi'}">（琼）网药信服务备字〔2022〕第003号[(琼)-经营性-2022-0002]</router-link>  </span><br/><span @click="goIcp">copyright  2021 海南九为医学科技有限公司 All Rights Reserved</span></div>
      </div>
      <el-dialog
        :visible.sync="dialogVisible"
        width="700px"
      >
        <img
          width="100%"
          :src="srcImg"
        />
      </el-dialog>
    </div>
  </div>

</template>

<script>
export default {
  name: "navFooter",
  data() {
    return {
      dialogVisible: false,
      srcImg: "",
    };
  },
  methods: {
    goSettle() {
      this.$router.push("/settlement");
    },
    showImg(name) {
      this.srcImg = require(`@/views/aboutUs/components/images/${name}.png`);
      this.dialogVisible = true;
    },
    goIcp() {
      window.open("https://beian.miit.gov.cn/#/Integrated/index");
    },
  },
};
</script>

<style lang="less" scoped>
.f-container {
  width: 100%;
  background: #4a4a4a;
  border-top: 1px solid #4a4a4a;
  border-bottom: 1px solid #4a4a4a;
}
.ff-outter {
  display: flex;
  justify-content: space-between;
  .d1 {
    padding-top: 60px;
    span {
      color: #fff;
      /*margin-top: 10px;*/
      font-size: 14px;
    }
    /*img {*/
    /*  !*display: block;*!*/
    /*}*/
  }
  .d2 {
    padding-top: 60px;
    text-align: right;
    span {
      color: #fff;
      display: block;
    }
    .s1 {
      font-size: 22px;
    }
    .s2 {
      font-size: 34px;
      font-weight: 600;
      margin-top: 20px;
    }
    .s3 {
      font-size: 14px;
      margin-top: 20px;
    }
    .s4 {
      font-size: 15px;
      margin-top: 20px;
      cursor: pointer;
      &:hover {
        color: #479bff;
      }
    }
  }
}
.t-s-footer {
  text-align: center;
  font-size: 0.3rem;
  color: #dcdadd;
  margin-bottom: 0.8rem;
  margin-top: 36px;
  line-height: 20px;
  a{
    color: #dcdadd;
    text-decoration: none;
  }
  span {
    cursor: pointer;
  }
}
.beian{

}
</style>